export const APP_SUB_FOLDER = "";
// export const APP_SUB_FOLDER = "/phpupgrade/mhc"; // Default value is empty, else values should be in this format /foldername

export let mainBaseURL = "";
export let cmsURL = "";
export let Domain = "";
export const envType = 'beta'; // 'prod/beta'


if(envType == 'prod'){
	mainBaseURL = "https://mhappapis.vc.myhealthcare.co/api/";
	cmsURL = "https://cms-api-in.myhealthcare.co/api/";
	Domain = "https://www.myhealthcare.co";
}else{
	// mainBaseURL = "https://prerelease.mhappapis.vc.myhealthcare.co/api/";
	// mainBaseURL = "https://uat.mhappapis.vc.myhealthcare.co/api/";
	// mainBaseURL = "http://3.110.112.150/api/";
	mainBaseURL = "https://mhappapis.vc.myhealthcare.co/api/";

	// For CMS 
	cmsURL = "https://betacms.myhealthcare.co/api/";
	Domain = "https://beta.myhealthcare.co";
}

export const site_SEO_META = true;
export const baseURL = mainBaseURL + "patients/web/v2/";
export const payLaterLink = baseURL + "doctors/thyro-thank-you/";
export const clinic_id = 15;
export const enquiryEmail = "support@myhealthcare.co";
export const callCenterTFN = { tel: "01141179678", tfn: "011 41179678" };
export const aggregatorType = "market_place";
export const apitoken = "eyJpdiI6IllkMVdCSVo5QnVacitJQlpcL3NJOTJBPT0iLCJ2YWx1ZSI6IjRERHdxWlB2V0JDYnhzcFwvOEFBbzk1MUFCWllyaFZzczZrQnZ5dmRuYk1VPSIsIm1hYyI6ImVhNGJhZWRjZjI4YmNjOTNiNzEyNmEyYTE2ZDVkYWI3NmY2YWM1NmYxZDNhZTlmNjlhNWFlNTBkY2QxNGY1M2EifQ==";



export const RECAPTCHA_SITE_KEY = "6LejE9UgAAAAALVRsOZ63Za_sIblJcPnHCSXiHYN";
export const RECAPTCHA_SECRET_KEY = "6LejE9UgAAAAAP_UITQonv790o4objjeWwkH23WE";

export const DefaultMetaTag = { 
	title: "MyHealthcare | Online Video Consultation with Doctors, Book Doctor Appointments, Order Medicine, Diagnostic Test",
	description: "India's Number 1 Healthcare Company. We help you find the best doctor & hospital near you, get digital prescriptions, order medicines, book doctor appointments, order tests, get health packages, and online doctor consults, and get the best home healthcare services.",
	keyword: "Online Doctor Consultation,Book Doctor Appointments Online,Online Doctor Appointment,Online Medicine Order,Buy medicines online at low prices,Diagnostic Tests and Medical Procedures"
};

export function changeDayString(day) {
	let originalDay = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	].indexOf(day);
	let tempDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][originalDay];
	return tempDay;
}

export function getDayNumber(day) {
	let originalDay = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	].indexOf(day);
	return originalDay;
}

export function calculateAge(dob) {
	if (dob) {
		var from = dob.split("/");
		var birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
		var cur = new Date();
		var diff = cur - birthdateTimeStamp;
		// This is the difference in milliseconds
		var currentAge = Math.floor(diff / 31557600000);
		return currentAge;
	} else {
		return 0
	}
}

export function dateFormatIntoDDMMYYYY(x) {
	let today = new Date(x);
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1;
	let dd = today.getDate();

	if (dd < 10) dd = "0" + dd;
	if (mm < 10) mm = "0" + mm;
	return dd + "/" + mm + "/" + yyyy;
}

export function dateFormatIntoMMDDYYYY(x) {
	let today = new Date(x);
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1;
	let dd = today.getDate();

	if (dd < 10) dd = "0" + dd;
	if (mm < 10) mm = "0" + mm;
	return mm + "-" + dd + "-" + yyyy;
}

export function dateFormatIntoYYYYMMDD(x) {
	let today = new Date(x);
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1;
	let dd = today.getDate();

	if (dd < 10) dd = "0" + dd;
	if (mm < 10) mm = "0" + mm;
	return yyyy + "-" + mm + "-" + dd;
}

export function timeConvertIntoAM_PM(time) {
	time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
		time,
	];

	if (time.length > 1) {
		time = time.slice(1);
		time[5] = +time[0] < 12 ? " AM" : " PM";
		time[0] = +time[0] % 12 || 12;
	}
	return time.join("");
}

export function currentTimeConvertIntoAM_PM() {
	var date = new Date();
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? "PM" : "AM";
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? "0" + minutes : minutes;
	var strTime = hours + ":" + minutes + " " + ampm;
	return strTime;
}

export function tConvert(time) {
	return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
		timeZone: "UTC",
		hour12: true,
		hour: "numeric",
		minute: "numeric",
	});
}

export function onlyNumber(e) {
	var code = (e.which) ? e.which : e.keyCode;
	if (code > 31 && (code < 48 || code > 57)) {
		return e.preventDefault();
	}
}

export function parseDMY(s) {
	let [d, m, y] = s.split(/\D/);
	return new Date(y, m - 1, d);
}


export const sessionTimeOut=60*60 // 3600 seconds